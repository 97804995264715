import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";

export function useRules() {
  const useNuxt = useNuxtApp();
  const { t } = useNuxt.$i18n;

  function required(val: string) {
    return !!val || t("utils.rules.required");
  }

  function valEmail(mail: string | null) {
    if (!mail)
      return true;
    return isEmail(mail ?? "") || t("pages.login.rules.email");
  }

  function valIBAN(val: string) {
    const paymentModule = usePaymentModule();
    return paymentModule.validateIban(val) || t("rules.isIban");
  }
  function valPhoneNumber(val: string) {
    if (!val)
      return true;
    return isMobilePhone(val) || t("utils.rules.validPhone");
  }

  function min3Chars(val: any) {
    return val.length > 3 || t("pages.onboard.rules.min3Chars");
  }
  function valVendorStoreIDLen(v: string) {
    return v?.length <= 36 || t("pages.storeManagement.storeDialog.lessThen36");
  }
  function valStoreId(val: string) {
    return !/[^a-z0-9-]/i.test(val)
      || t("pages.storeManagement.storeDialog.storeIdValid");
  }
  function valUrl(val: string | null | undefined) {
    if (!val)
      return t("utils.rules.required");
    return isURL(val)
      || t("pages.marketing.contentManagement.contentManagementDialog.rules.validUrl");
  }

  function preventLongTitle(title: string) {
    return title?.length <= 20
      || t(
        "pages.marketing.contentManagement.contentManagementDialog.rules.longTitle",
      );
  }

  function passwordStrength(password: string) {
    const re = /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*\d))(?=(.*[!-])).*$/;
    return (
      re.test(password)
      || t("utils.rules.passwordRuleApi")
    );
  };

  function passwordStrengthUser(password: string) {
    const re = /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*\d))(?=(.*[!@#$%^&*()\-_+.])).*$/;
    return (
      re.test(password)
      || t("utils.rules.passwordRuleUser")
    );
  };

  function passwordLength(password: string) {
    return password?.length >= 8 || t("utils.rules.pwLengthUser");
  }

  function passwordLengthLong(password: string) {
    return password?.length >= 16 || t("utils.rules.pwLength");
  }

  return {
    required,
    valEmail,
    valPhoneNumber,
    min3Chars,
    valStoreId,
    valVendorStoreIDLen,
    valUrl,
    valIBAN,
    preventLongTitle,
    passwordStrength,
    passwordLength,
    passwordLengthLong,
    passwordStrengthUser,
  };
}
